/* eslint-disable @typescript-eslint/no-unused-expressions */
import {ChangeEvent, MouseEvent, useCallback, useEffect, useState} from 'react'

import {useNavigate} from 'react-router-dom'
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Pagination,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  debounce
} from '@material-ui/core'
import X from '../../../icons/X';
import Scrollbar from '../../../../components/Scrollbar'
import {AccountType, CSS_CENTER, PAGE_LIMITS, RIGHT_VERTICAL_BAR_COUNT, VERTICAL_TITLE_BAR} from '../../../consts'
import MyRowsPerPage from '../../MyRowsPerPage'
import {Recipient, SearchFilter} from '../../../types/user'
import {applyPagination, applyStringSortG, handleClickSort} from '../../../utils/table'
import {getCPEStatusLabel, MyTableA, SX_TABLE_HEADER_A, getSortIcon} from '../../../commonStyles'
import SimpleYesNoModal from '../../widgets/modals/SimpleYesNoModal'
import {toast} from 'react-hot-toast'
import XlsxControl from '../../widgets/XlsxControl'
import {apiClient} from '../../../api/common'
import {carewellAuthApi} from '../../../api/carewellAuthApi'
import RecipientDeleteModal from './RecipientDeleteModal';
import useMounted from 'src/hooks/useMounted';
import useAuth from 'src/hooks/useAuth';
import { checkToken } from 'src/__CareWell__/utils/tokenChecker';
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RecipientList from 'src/__CareWell__/pages/users/RecipientList';
import ServiceEndTimeModal from '../../widgets/modals/ServiceEndTimeModal';



const baseTableUrl = '/dashboard/users/recipients'

const HEIGHT = 72
const TITLE_WIDTH = 100

interface Props {
  limit : number,
  setLimit : (number) => void,
  allCount : number,
  page: number,
  setPage : (number) => void,
  recipientList: Recipient[],
  willHideRegionColumn: boolean,
  doUpdateTable: ()=>void,
  loading: boolean,
  sortList : string,
  setSortList : (string) => void,
  clickCount : number,
  setClickCount : (number) => void,
  sortColumns?: string[],
}

const data = [
  {
    id: '정상',
    value: '정상'
  },
  {
    id: '주의',
    value: '주의'
  },
  {
    id: '위험',
    value: '위험'
  },
  {
    id: '기타',
    value: '기타'
  }
];

// let sheetHeader = ['지역', '이름', '인증상태', '대상자ID']
// let sheetKeyNames = ['silver_public_care_area', 'silver_name', 'ai_speaker_certificate_yn', 'silver_id']
// let sortColumns = ['silver_public_care_area', 'silver_name', 'ai_speaker_certificate_yn', 'ai_speaker_user_aggrement_date', 'ai_speaker_sn', 'silver_id']

export default function RecipientListTableV2(props: Props) {
  const {doUpdateTable, limit, setLimit, allCount, page, setPage, recipientList, willHideRegionColumn, loading, sortList, setSortList, clickCount, setClickCount, sortColumns} = props;
  const isHCenter = carewellAuthApi.getAccountType() === AccountType.HCenterManager
  const isKHealthMember = carewellAuthApi.getAccountType() === AccountType.KHealthMember
  const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator
  const navigate = useNavigate()
  const mounted = useMounted();
  const {logout} = useAuth()
  const [selectRows, setSelectRows] = useState<Recipient[]>([]);
  const [checkedRows, setCheckedRows] = useState<boolean[]>([]);
  const [allChecked, setAllChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [currentItem, setCurrentItem] = useState<Recipient>();
  const [popupDeleteUser, setPopupDeleteUser] = useState(false)
  const [actionRowId, setActionRowId] = useState('') // 삭제, 비번초기화 등을 위한 row id
  const [modalTop, setModalTop] = useState(0);
  const [modalLeft, setModalLeft] = useState(0);
  const [selection, setSelection] = useState({value: '안전'});
  const [sheetRows, setSheetRows] = useState<any[]>([])
  const [serviceEndTimeRows, setServiceEndTimeRows] = useState<any[]>([])
  const [serviceEndTimeDate, setServiceEndTimeDate] = useState('')

  const [sheetHeader, setSheetHeader] = useState<any[]>([]);
  useEffect(() => {
    const rows = Array(recipientList.length).fill(false)
    setCheckedRows(rows);

    if (carewellAuthApi.getAccountType() === AccountType.MunicipalityManager) {
      const headerlist = [];
      headerlist.push({value: '이름', key: 'silver_name'})
      headerlist.push({value: '인증상태', key: 'ai_speaker_certificate_yn'})
      headerlist.push({value: '대상자ID', key: 'silver_id'})
      setSheetHeader(headerlist);
    } else {
      const headerlist = [];
      headerlist.push({value: '지역', key: 'silver_public_care_area'})
      headerlist.push({value: '이름', key: 'silver_name'})
      headerlist.push({value: '인증상태', key: 'ai_speaker_certificate_yn'})
      headerlist.push({value: '대상자ID', key: 'silver_id'})
      setSheetHeader(headerlist);
    }
    return () => {

    };
  }, [mounted])

  useEffect(() => {
    const onScroll = () => {
      setShowModal(false);
    };
    window.addEventListener('scroll', onScroll, { capture: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const updateStatus = async (user_id, status) => {
    checkToken(logout, navigate).then(async (config) => {
      const updateSatusUrl = '/update_safety_status'
      const res = await apiClient.post(updateSatusUrl, {
        user_id,
        status,
        activity: 0,
        additional: 0,
      }, config)
      console.log(`[API ${updateSatusUrl}]: `, res.data)

      if (res.data.state === 'ok') toast.success('상태가 정상적으로 변경되었습니다.')
      else toast.error('상태 변경에 실패하였습니다.')
    });
  }

  const updateSelection = (e, v) => {
    e.persist();
    setSelection({ ...selection, value: v });
  };

  const onRowsPerPageChange = useCallback((limitNumber: number): void => {
    debounce((event, value) => {
      setLimit(limitNumber)
      setPage(0) // 페이지 rows 수 변경 시 1페이지부터 보여줌
    }, 200) /// 페이징 더블클릭시 에러 있음
  }, [])

  const setRows = (list) => {
    setSelectRows(list);
    if (list.length === 0) {
      setSheetRows([])
      return;
    }
    const selectrows = [];
    const header = [];
    sheetHeader.forEach((label) => header.push(label.value))
    selectrows.push(header)
    list.forEach((element) => {
      const sheetRow = []
      sheetHeader.forEach((label) => sheetRow.push(element[label.key]))
      selectrows.push(sheetRow)
    });
    setSheetRows(selectrows);
  }

  const handleAllChecked = (e) => {
    setAllChecked((prevCheckedAll) => {
      const checked = !prevCheckedAll;
      const newCheckRows = Array(recipientList.length).fill(checked);
      setCheckedRows(() => newCheckRows);
      if (checked) {
        setRows(recipientList)
        setServiceEndTimeRows(recipientList)
        // setSelectRows(recipientList)
        // setDisalbeXlsx(false)
      } else {
        setRows([])
        setServiceEndTimeRows([])
        // setDisalbeXlsx(true)
      }
      return checked;
    })
  }

  const handleCellClick = (item: Recipient) => {
    navigate(`${baseTableUrl}/${item.silver_id}/details`, {
      state: {
        pathName: window.location.pathname
      }
    })
  }

  const handleDelete = (item: Recipient) => {
    //
    console.log('handleDelete id: ', item)
    setPopupDeleteUser(true)
    setActionRowId(item.silver_id)
  }

  const onFinishDeleteUser = (success:boolean) => {
    if (success) {
      doUpdateTable()
    }
  }

  const handleChangeCheckBox = async (index) => {
    const checked = !checkedRows[index]
    await setCheckedRows((prev) => {
      const prevrows = [...prev];
      prevrows[index] = checked;
      let newData = selectRows;
      if (checked) {
        newData.push(recipientList[index]);
      } else {
        const seniorname = recipientList[index].silver_id;
        newData = newData.filter((item) => item.silver_id !== seniorname);
      }
      setRows(newData)
      setServiceEndTimeRows(newData)
      console.log(newData)
      return prevrows;
    });
  }

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    if (newPage != null) {
      setPage(newPage - 1)
    }
  }

  const handleSortList = (sortValue) => {
    if (sortList !== sortValue) {
      setSortList(sortValue)
      setClickCount(1)
    } else {
      setClickCount((prevCount) => prevCount + 1)
    }
  }

  // const SX_SORT_COLOR = ['#26AAD6', 'primary', '#26AAD6']

  // const getSortIcon = (sortIndex: number) => {
  //   switch (sortIndex) {
  //     case -1:
  //       return <ExpandLessIcon sx={{color: SX_SORT_COLOR[0]}} />
  //     case 1:
  //       return <ExpandMoreIcon sx={{color: SX_SORT_COLOR[2]}} />
  //     default:
  //       return <ExpandMoreIcon sx={{color: SX_SORT_COLOR[1]}} />
  //   }
  // }

  // const handleClickSort = () => {
  //   setSortIndex((prevSortIndex) => (prevSortIndex === 1 ? -1 : 1));
  // }

  // 안전 모니터링 모달
  const MonitoringModal = () => (
    <Box sx={{
      zIndex: 100,
      display: showModal ? 'block' : 'none',
      position: 'fixed',
      top: modalTop,
      left: `${modalLeft + 10}px`,
      width: 180,
      bgcolor: 'white',
      border: '2px solid #000',
      boxShadow: '24',
      p: 2,
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start'
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
        >
          <Typography display="inline-block" color="textPrimary" variant="h6" sx={{...VERTICAL_TITLE_BAR, mb: 1}}>
            {`현상태: ${selection ? selection.value : ''}`}
          </Typography>
          <X sx={{cursor: 'pointer', float: 'right', width: '20px'}} onClick={(e) => {
            e.preventDefault();
            setShowModal(false);
          }}
          />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
        >
          <Typography display="inline-block" color="textPrimary" variant="h6" sx={{...VERTICAL_TITLE_BAR}}>
            상태 변경:
          </Typography>
          <RadioGroup
            sx={{
              pl: 1,
            }}
            name="value"
            value={selection.value}
            onChange={updateSelection}
          >
            {data.map((datum) => (
              <FormControlLabel
                label={datum.value}
                key={datum.id}
                value={datum.id}
                control={<Radio color="primary" />}
              />
            ))}
          </RadioGroup>
          <Button variant="outlined" onClick={async (e) => {
            await updateStatus(currentItem.silver_id, selection.value);
            const currentValue = currentItem;
            currentValue.silver_safety_status = selection.value;
            setCurrentItem(currentValue);
            setShowModal(false);
          }}
          >
            변경
          </Button>
        </Box>
      </Box>
    </Box>
  )

  return (
    <Box sx={{border: 0}}>
      <Box>
        <Scrollbar>
          <Box sx={{minWidth: 900}}>
            <Grid container justifyContent="space-between">
              <Grid item sx={{ml: 2, mr: 2, mb: 0}}>
                <Box sx={{display: 'flex', alignItems: 'center', p: 0, m: 0, bgcolor: 'background.paper', height: HEIGHT}}>
                  <Box sx={{p: 1, ml: 2}}>
                    <Typography width={TITLE_WIDTH} align="right" sx={{...RIGHT_VERTICAL_BAR_COUNT}}>{`전체: ${allCount}`}</Typography>
                  </Box>
                  <Box sx={{p: 1}}>
                    <MyRowsPerPage rowsPerPage={limit} setRowsPerPage={setLimit} rowsPerPageOptions={PAGE_LIMITS} onRowsPerPageChange={onRowsPerPageChange} />
                  </Box>
                  <Box sx={{p: 1}} />
                </Box>
              </Grid>
              <Grid item sx={{mr: 2, pt: 2, display: 'flex', alignItems: 'center'}}>
                {isOperator && sheetHeader && (<Button variant="outlined" sx={{marginRight: '10px'}} onClick={() => { setShowModal2(true) }} disabled={serviceEndTimeRows.length === 0}>서비스 종료일 연장</Button>) }
                {/** 엑셀 다운로드 */}
                {sheetHeader && (<XlsxControl rows={sheetRows} filename="대상자" />) }
              </Grid>
            </Grid>
            <MyTableA size="small">
              <TableHead>
                <TableRow sx={SX_TABLE_HEADER_A}>
                  <TableCell align="center" padding="checkbox">
                    {/* <Checkbox checked={selectedAllRows} indeterminate={selectedSomeRows} onChange={handleSelectAllProducts} /> */}
                    <Checkbox checked={allChecked} onChange={handleAllChecked} />
                  </TableCell>
                  {!willHideRegionColumn
                  && (
                  <TableCell align="center">
                    지역
                    <IconButton size="small" onClick={() => { handleSortList('지역') }}>
                      {sortList === '지역' && clickCount % 2 === 0 ? (
                        <ExpandLessIcon sx={{ color: sortList === '지역' ? '#26AAD6' : 'primary' }} />
                      ) : (
                        <ExpandMoreIcon sx={{ color: sortList === '지역' ? '#26AAD6' : 'primary' }} />
                      )}
                    </IconButton>
                  </TableCell>
                  )}
                  <TableCell align="center">
                    이름
                    <IconButton size="small" onClick={() => { handleSortList('이름') }}>
                      {sortList === '이름' && clickCount % 2 === 0 ? (
                        <ExpandLessIcon sx={{ color: sortList === '이름' ? '#26AAD6' : 'primary' }} />
                      ) : (
                        <ExpandMoreIcon sx={{ color: sortList === '이름' ? '#26AAD6' : 'primary' }} />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">전화번호</TableCell>
                  <TableCell align="center">
                    인증상태
                    <IconButton size="small" onClick={() => { handleSortList('인증상태') }}>
                      {sortList === '인증상태' && clickCount % 2 === 0 ? (
                        <ExpandLessIcon sx={{ color: sortList === '인증상태' ? '#26AAD6' : 'primary' }} />
                      ) : (
                        <ExpandMoreIcon sx={{ color: sortList === '인증상태' ? '#26AAD6' : 'primary' }} />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    동의일시
                    <IconButton size="small" onClick={() => { handleSortList('동의일시') }}>
                      {sortList === '동의일시' && clickCount % 2 === 0 ? (
                        <ExpandLessIcon sx={{ color: sortList === '동의일시' ? '#26AAD6' : 'primary' }} />
                      ) : (
                        <ExpandMoreIcon sx={{ color: sortList === '동의일시' ? '#26AAD6' : 'primary' }} />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    대상자 서비스 종료일
                    <IconButton size="small" onClick={() => { handleSortList('대상자만료일자') }}>
                      {sortList === '대상자만료일자' && clickCount % 2 === 0 ? (
                        <ExpandLessIcon sx={{ color: sortList === '대상자만료일자' ? '#26AAD6' : 'primary' }} />
                      ) : (
                        <ExpandMoreIcon sx={{ color: sortList === '대상자만료일자' ? '#26AAD6' : 'primary' }} />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    스피커 서비스 종료일
                    <IconButton size="small" onClick={() => { handleSortList('스피커만료일자') }}>
                      {sortList === '스피커만료일자' && clickCount % 2 === 0 ? (
                        <ExpandLessIcon sx={{ color: sortList === '스피커만료일자' ? '#26AAD6' : 'primary' }} />
                      ) : (
                        <ExpandMoreIcon sx={{ color: sortList === '스피커만료일자' ? '#26AAD6' : 'primary' }} />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    시리얼번호
                  </TableCell>
                  <TableCell align="center">MAC주소</TableCell>
                  <TableCell align="center">
                    대상자ID
                  </TableCell>
                  { !isHCenter
                    && <TableCell align="center">안전 모니터링</TableCell>}
                  {!isKHealthMember && (<TableCell align="center">회원관리</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  recipientList.map((item, index) => {
                    const keyName = item.silver_id
                    return (
                      <TableRow hover key={keyName} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell align="center" padding="checkbox">
                          <Checkbox color="primary" checked={(checkedRows && checkedRows.length > 0 && checkedRows[index])} onChange={() => { handleChangeCheckBox(index) }} />
                        </TableCell>
                        {!willHideRegionColumn
                        && (
                          <TableCell align="center" onClick={() => handleCellClick(item)}>{item.silver_public_care_area}</TableCell>
                        )}
                        <TableCell align="center" onClick={() => handleCellClick(item)}>{item.silver_name}</TableCell>
                        <TableCell align="center" onClick={() => handleCellClick(item)}>{item.silver_tel}</TableCell>
                        <TableCell align="center" onClick={() => handleCellClick(item)}>{item.ai_speaker_certificate_yn}</TableCell>
                        <TableCell align="center" onClick={() => handleCellClick(item)}>{item.ai_speaker_user_aggrement_date}</TableCell>
                        <TableCell align="center" onClick={() => handleCellClick(item)}>{item.silver_service_end_time}</TableCell>
                        <TableCell align="center" onClick={() => handleCellClick(item)}>{item.ai_speaker_service_end_time}</TableCell>
                        <TableCell align="center" onClick={() => handleCellClick(item)}>{item.ai_speaker_sn}</TableCell>
                        <TableCell align="center" onClick={() => handleCellClick(item)}>{item.ai_speaker_macaddress}</TableCell>
                        <TableCell align="center" onClick={() => handleCellClick(item)}>{item.silver_id}</TableCell>
                        { !isHCenter
                          && (
                          <TableCell align="center">
                            <Typography
                              sx={{
                                margin: '0 auto',
                                width: 'fit-content'
                              }}
                              onMouseOver={(e) => {
                                e.preventDefault();
                                if (!showModal) {
                                  setShowModal(true);
                                }
                                setCurrentItem(item)
                                updateSelection(e, item.silver_safety_status);
                              }}
                              onMouseMove={(e) => {
                                e.preventDefault();
                                setModalLeft(e.clientX);
                                setModalTop(e.clientY);
                              }}
                              fontSize={12} fontWeight={600}
                            >
                              [보기]
                            </Typography>
                          </TableCell>
                          )}
                        {!isKHealthMember && (<TableCell align="center"><Typography onClick={() => handleDelete(item)} fontSize={12} fontWeight={600}>[삭제]</Typography></TableCell>)}
                      </TableRow>
                    )
                  })
              }
              </TableBody>
            </MyTableA>
            <Divider />
            <Box display="flex" justifyContent="center" sx={{my: 3}}>
              {recipientList.length === 0 && <Typography>조회 내역이 없습니다.</Typography>}
            </Box>

            <Box sx={{...CSS_CENTER, my: 2}}>

              <Pagination
                siblingCount={5}
                count={allCount % limit > 0 ? Math.floor(allCount / limit + 1) : Math.floor(allCount / limit)}
                page={page + 1}
                onChange={handlePageChange}
              />
            </Box>
          </Box>
        </Scrollbar>
      </Box>
      <RecipientDeleteModal open={popupDeleteUser} setOpen={setPopupDeleteUser} rowId={actionRowId}
        userId={carewellAuthApi.loggedInUser.id} onFinish={onFinishDeleteUser}
      />
      <ServiceEndTimeModal open={showModal2} setOpen={setShowModal2} userList={serviceEndTimeRows} time={serviceEndTimeDate} setTime={setServiceEndTimeDate} />
      <MonitoringModal />
    </Box>
  )
}
