/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useState, useRef } from 'react'
import { Box, Button, Card, Container, Grid, Link } from '@material-ui/core'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { commonApi } from 'src/__CareWell__/api/commonApi'
import useMyApp from 'src/__CareWell__/hooks/useMyApp'
import { checkToken } from 'src/__CareWell__/utils/tokenChecker'
import useAuth from 'src/hooks/useAuth'
import { useLocation } from 'react-router'
import useMounted from '../../../hooks/useMounted'
import useSettings from '../../../hooks/useSettings'
import gtm from '../../../lib/gtm'
import { carewellAuthApi } from '../../api/carewellAuthApi'
import { apiClient } from '../../api/common'
import PageHeader from '../../components/dashboard/PageHeader'
import RecipientListTable from '../../components/dashboard/users/RecipientListTable'
import UserListSearch from '../../components/dashboard/users/UserListSearch'
import RecipientRegisterBulkModal from '../../components/widgets/modals/RecipientRegisterBulkModal'
import { AccountType, CONTENT_LAYOUT_PY, PAGE_LIMITS } from '../../consts'
import { recipientSearchOptions, recipientSearchOptionsForHCenter } from '../../options'
import UserListSearchV2 from 'src/__CareWell__/components/dashboard/users/UserListSearchV2'
import RecipientListTableV2 from 'src/__CareWell__/components/dashboard/users/RecipientListTableV2'
import { DotLoader } from 'react-spinner-overlay';
import styled from 'styled-components';
import { SearchFilter } from '../../types/user';

const Loading = styled.div`
  width:100%;
  height:100%;
  min-height: 400px;
  position:absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:white;
  opacity: 0.8;
  z-index:999;
`

const urlCreateRecord = '/dashboard/users/recipients/new'

const SESSION_STORAGE_KEY = 'recipientListSearchFilter';

const storagePath = 'prevPath'

const targetPathPrefix = '/dashboard/users/recipients';

const initializeSearchFilter = () => {
  // const storagePathValue = sessionStorage.getItem(storagePath);
  // if (storagePathValue && storagePathValue.startsWith(targetPathPrefix) === false) {
  //   console.log('여기')
  //   return undefined;
  // }

  const storedFilter = sessionStorage.getItem(SESSION_STORAGE_KEY);
  if (!storedFilter) {
    return undefined;
  }
  // const storedFilter = sessionStorage.getItem(SESSION_STORAGE_KEY);
  return JSON.parse(storedFilter);
};

// let sheetHeader = ['지역', '이름', '인증상태', '대상자ID']
// let sheetKeyNames = ['silver_public_care_area', 'silver_name', 'ai_speaker_certificate_yn', 'silver_id']
// let sortColumns = ['silver_public_care_area', 'silver_name', 'ai_speaker_certificate_yn', 'ai_speaker_user_aggrement_date', 'ai_speaker_sn', 'silver_id']

interface LocationState {
  prevPath: string,
  searchData: any | null
}

// 대상자 조회
export default function RecipientListV2() {
  const { settings } = useSettings()
  const {logout} = useAuth()
  const navigate = useNavigate()
  const accountType = carewellAuthApi.getAccountType();
  const location = useLocation();
  const isKHealthMember = carewellAuthApi.getAccountType() === AccountType.KHealthMember
  const isHCenterManager = carewellAuthApi.getAccountType() === AccountType.HCenterManager
  const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator
  const isMunicipalityManager = carewellAuthApi.getAccountType() === AccountType.MunicipalityManager
  const suppix = isOperator ? '관리' : ''
  const willHideRegionColumn = isHCenterManager || isMunicipalityManager;
  const {states, setStates} = useMyApp()
  const [municipalitiesList, setMunicipalitiesList] = useState([])
  const [hCenterList, setHCenterList] = useState([])
  const [recipientList, setRecipientList] = useState([])
  // const [searchFilter, setSearchFilter] = useState<SearchFilter>()
  const [searchFilter, setSearchFilter] = useState<SearchFilter>(initializeSearchFilter);
  const [limit, setLimit] = useState(PAGE_LIMITS[1]);
  const [page, setPage] = useState(0)
  const [sortList, setSortList] = useState('')
  const [clickCount, setClickCount] = useState(0)
  const [allCount, setAllCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const initHCenterList = async (config) => {
    const hCenterArray = [];
    const hCenters = await commonApi.getHCenterList(config)
    Object.keys(hCenters).forEach((key) => {
      const centerlist = hCenters[key]
      centerlist.map((item, index) => {
        if (item === '') return null;
        hCenterArray.push({area: key, value: item})
        return null;
      })
    })
    hCenterArray.sort((a, b) => {
      if (a.area > b.area) return 1
      if (a.area < b.area) return -1
      if (a.value > b.value) return 1
      if (a.value < b.value) return -1
      return 1
    })
    setStates({...states, hCenterMap: hCenters})
    setHCenterList(hCenterArray)
  }

  const initMunicipalityList = async (config) => {
    let municipalityArray = []
    const municipalities = await commonApi.getMunicipalityList(config)
    Object.keys(municipalities).forEach((key) => {
      municipalities[key] = [...new Set(municipalities[key])];
      municipalityArray = municipalityArray.concat(Array.from(municipalities[key]).filter((n) => n))
    })
    municipalityArray.sort()
    setStates({...states, municipalityMap: municipalities})
    setMunicipalitiesList(municipalityArray)
  }

  const getListCount = async (config) => {
    const url = '/get_silver_list_count_v2'
    const reqData = {
      user_id: carewellAuthApi.loggedInUser.id,
      req_type: carewellAuthApi.getAccountType() === AccountType.Operator ? 'operator' : carewellAuthApi.getAccountType() === AccountType.HCenterManager ? 'hcenter' : 'municipality',
      user_status: '등록',
      area: null,
      start_date: null,
      end_date: null,
      search_item: null,
      search_content: null
    }

    if (searchFilter != null) {
      reqData.area = searchFilter.area;
      reqData.start_date = searchFilter.fromdate;
      reqData.end_date = searchFilter.enddate;
      reqData.search_item = searchFilter.searchItem;
      reqData.search_content = searchFilter.searchContent;
    }

    const res = await apiClient.post(url, reqData, config)
    if (res.data.state === 'ok') {
      // console.log(res.data)
      setAllCount(res.data.result.count);
    }
  }

  const getList = async (config) => {
    console.log(searchFilter)
    setLoading(true)
    const url = `/get_silver_list_v2/${page}/${limit}`
    const reqData = {
      user_id: carewellAuthApi.loggedInUser.id,
      req_type: carewellAuthApi.getAccountType() === AccountType.Operator ? 'operator' : carewellAuthApi.getAccountType() === AccountType.HCenterManager ? 'hcenter' : 'municipality',
      user_status: '등록',
      area: null,
      start_date: null,
      end_date: null,
      search_item: null,
      search_content: null,
      sortList: null,
      count: 0
    }

    if (searchFilter != null) {
      reqData.area = searchFilter.area;
      reqData.start_date = searchFilter.fromdate;
      reqData.end_date = searchFilter.enddate;
      reqData.search_item = searchFilter.searchItem;
      reqData.search_content = searchFilter.searchContent;
    }
    if (sortList != null) {
      reqData.sortList = sortList;
    }
    if (clickCount > 0) {
      reqData.count = clickCount;
    }
    console.log(reqData)
    const res = await apiClient.post(url, reqData, config)
    if (res.data.state === 'ok') {
      setRecipientList(res.data.result)
    }
    setLoading(false)
  }

  const doUpdateTable = () => {
    checkToken(logout, navigate).then((config) => {
      getList(config)
    });
  }

  useEffect(() => {
    // setRecipientList([])
    doUpdateTable()
  }, [page, limit, sortList, clickCount])

  useEffect(() => { // 검색 버튼을 누른 최초1회 동작
    checkToken(logout, navigate).then((config) => {
      setPage(0)
      getListCount(config)
      getList(config)
    });
  }, [searchFilter])

  useEffect(() => {
    if (searchFilter) {
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(searchFilter));
    }
  }, [searchFilter]);

  useEffect(() => {
    console.log('searchFilter :', searchFilter)
  }, [searchFilter])

  useEffect(() => {
    console.log('sessionStorage :', sessionStorage)
    checkToken(logout, navigate).then((config) => {
      initHCenterList(config);
      initMunicipalityList(config);
      getListCount(config);
      getList(config)
    });
  }, []);

  return (
    <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: CONTENT_LAYOUT_PY}}>
      <Container maxWidth={settings.compact ? 'xl' : false}>
        <PageHeader title="대상자 조회" paths={['홈', `회원${suppix}`, '대상자', '조회']} links={['/', '', '', '']} />
        <Box sx={{mt: 3}}>
          <UserListSearchV2 hcenterlist={hCenterList} municipalitieslist={municipalitiesList}
            categorySearch={accountType === AccountType.Operator ? recipientSearchOptions : recipientSearchOptionsForHCenter}
            setSearchFilter={setSearchFilter} searchFilter2={searchFilter}
          />
        </Box>
        <Box sx={{mb: 2}} />
        <Card style={{position: 'relative'}}>
          <Box sx={{position: 'relative'}}>
            {loading && <Loading><DotLoader loading={loading} size={10} /></Loading>}
            <RecipientListTableV2 loading={loading} doUpdateTable={doUpdateTable} willHideRegionColumn={willHideRegionColumn} recipientList={recipientList} page={page} setPage={setPage}
              allCount={allCount} limit={limit} setLimit={setLimit} sortList={sortList} setSortList={setSortList} clickCount={clickCount} setClickCount={setClickCount}
            />
          </Box>
          { !isKHealthMember && (
            <Grid container justifyContent="space-between">
              <Grid item sx={{ml: 1, mb: 0}} />
              <Grid item sx={{mr: 1, mb: 2}}>
                <Button variant="outlined" sx={{mt: 2, mr: 1}}>
                  <Link color="textPrimary" component={RouterLink} to={urlCreateRecord} variant="subtitle2">대상자 개별등록</Link>
                </Button>
              </Grid>
            </Grid>
          )}
        </Card>
      </Container>
    </Box>
  )
}
